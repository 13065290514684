<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" :title="this.isAdd ? '新建保证金 ' : '编辑保证金'" width="960px" @closed="handleClosed">
    <template v-if="form">
      <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="100px" label-suffix=":" :disabled="disabled">
        <div class="h sb">
          <el-form-item prop="entId" label="供应商">
            <!-- <quick-select v-model="form.distributorEntId" filterable :label.sync="form.distributorName" value-field="enterpriseId" url="api/distributor" auto-select-first-option placeholder="请选择" style="width: 230px;" @change="handleDistributor" /> -->
            <quick-select v-model="form.entId" filterable clearable url="api/purchase/seller" placeholder="选择供应商" auto-select-first-option value-field="belongTo" display-field="belongToName" style="width: 230px;" />
          </el-form-item>
          <el-form-item prop="subjectId" label="活动">
            <quick-select v-model="form.subjectId" :filterable="true" :label.sync="form.subjectName" value-field="id" :params="{now:true,entId:form.entId}" url="api/subject" placeholder="请选择" style="width: 230px;" @change="handleSubjectName" @inited="handleSubjectName" />
          </el-form-item>
        </div>
        <el-form-item label="活动详情">
          <el-table :data="store.items" empty-text="活动详情" v-loading="store.loading">
            <el-table-column prop="name" label="活动" min-width="120" />
            <el-table-column label="活动类型">
              <template slot-scope="scope">
                <el-tag type="info">{{status[scope.row.subjectType].label}}</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="money" label="收费金额" align="right" width="120" :formatter="$price" />
            <el-table-column prop="begDate" label="开始日期" width="90" :formatter="v=>{return v.begDate?new Date(v.begDate).format('yyyy/MM/dd'):''}" />
            <el-table-column prop="endDate" label="结束日期" width="90" :formatter="v=>{return v.endDate?new Date(v.endDate).format('yyyy/MM/dd'):''}" />
            <el-table-column prop="info" label="活动说明" show-overflow-tooltip min-width="240" />
          </el-table>
        </el-form-item>

        <el-form-item label="备注">
          <el-input type="textarea" v-model="form.info" :maxlength="200" :rows="3" resize="none" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="dialog = false">取消</el-button>
        <el-button type="primary" :loading="loading" @click="doSave">确认</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { edit } from "@/api/deposit";
import request from "@/utils/request";
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      disabled: false,
      form: {
        entId: null,
        subjectId: null,
        info: null,
      },
      store: {
        items: [],
        loading: false,
      },
      rules: {
        entId: [{ required: true, message: "请选择供应商" }],
        subjectId: [{ required: true, message: "请选择活动" }],
      },
      status: {
        purpose: {
          label: "窜货保证金",
        },
        signup: {
          label: "报名费",
        },
        ensure: {
          label: "保证金",
        },
      },
    };
  },

  methods: {
    handleClosed() {
      this.form = null;
      this.store.items = null;
    },
    doSave() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.isAdd) {
            this.doAdd();
          } else {
            this.doEdit();
          }
        }
      });
    },
    doAdd() {
      request({
        url: "api/deposit/distributor",
        method: "post",
        data: this.form,
      })
        .then((res) => {
          this.dialog = false;
          this.$notify({
            title: "保存成功",
            type: "success",
            duration: 2500,
          });
          this.loading = false;
          this.$parent.init();
        })
        .catch((err) => {
          this.loading = false;
          console.log(err.response.data.message);
        });
    },
    doEdit() {
      edit(this.form)
        .then((res) => {
          this.dialog = false;
          this.$notify({
            title: "修改成功",
            type: "success",
            duration: 2500,
          });
          this.loading = false;
          this.$parent.init();
        })
        .catch((err) => {
          this.loading = false;
          console.log(err.response.data.message);
        });
    },
    restForm(form) {
      this.dialog = true;
      this.disabled = false;
      this.form = form || {
        entId: null,
        subjectId: null,
        info: null,
      };
    },
    // handleDistributor(id, old, row) {
    //   if (row) {
    //     this.form.distributorEntId = row.enterpriseId;
    //   }
    // },
    handleSubjectName(id, old, row) {
      if (row) {
        this.store.items = [row];
        this.form.subjectId = row.id;
      }
    },
  },
};
</script>